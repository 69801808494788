import React from 'react'

export default function DesignRushSvgBadge() {
  return (
    <svg width="240" height="94" viewBox="0 0 240 94" fill="none" xmlns="http://www.w3.org/2000/svg" className='w-full'>
<rect width="240" height="94" rx="17" fill="#1D1E22"/>
<g clip-path="url(#clip0_0_1)">
<path d="M53.4473 40.9165H60.2386C65.7047 40.9165 69.5145 44.6665 69.5145 49.4998V49.5832C69.5145 54.4165 65.7876 58.1665 60.2386 58.1665H53.4473V40.9165ZM60.2386 54.6665C63.3858 54.6665 65.4563 52.5832 65.4563 49.5832V49.4998C65.4563 46.4998 63.3858 44.3332 60.2386 44.3332H57.3398V54.6665H60.2386Z" fill="white"/>
<path d="M72.8271 40.9165H85.9957V44.2498H76.7197V47.7498H84.919V51.0832H76.7197V54.6665H86.1613V57.9998H72.91L72.8271 40.9165Z" fill="white"/>
<path d="M88.3145 55.5832L90.6334 52.9165C92.207 54.1665 93.8634 54.9998 95.8511 54.9998C97.4247 54.9998 98.3358 54.3332 98.3358 53.4165V53.3332C98.3358 52.3332 97.6732 51.8332 94.8573 51.1665C91.3788 50.2498 89.0598 49.3332 89.0598 45.9165V45.8332C89.0598 42.7498 91.5445 40.6665 95.1886 40.6665C97.6732 40.6665 99.9094 41.4165 101.649 42.8332L99.6609 45.7498C98.1701 44.6665 96.5965 44.0832 95.1058 44.0832C93.615 44.0832 92.8696 44.7498 92.8696 45.5832V45.4998C92.8696 46.5832 93.615 46.9998 96.5965 47.7498C100.158 48.6665 102.063 49.9165 102.063 52.8332V52.9165C102.063 56.3332 99.4124 58.2498 95.6855 58.2498C93.0352 58.2498 90.385 57.3332 88.3145 55.5832Z" fill="white"/>
<path d="M105.542 40.9165H109.352V58.0832H105.542V40.9165Z" fill="white"/>
<path d="M112.995 49.4999C112.995 44.5833 116.805 40.5833 122.105 40.5833C125.253 40.5833 127.075 41.4166 128.897 42.9999L126.495 45.9166C125.17 44.8333 124.01 44.1666 121.94 44.1666C119.124 44.1666 116.971 46.5833 116.971 49.4999V49.5833C116.971 52.7499 119.124 55.0833 122.271 55.0833C123.679 55.0833 124.921 54.7499 125.915 53.9999V51.5833H122.023V48.3333H129.559V55.7499C127.737 57.2499 125.335 58.4999 122.105 58.4999C116.639 58.2499 112.995 54.5833 112.995 49.4999Z" fill="white"/>
<path d="M133.204 40.9165H136.765L144.882 51.4165V40.9165H148.609V58.0832H145.379L136.931 47.1665V58.0832H133.204V40.9165Z" fill="white"/>
<path d="M152.833 40.9167H160.783C163.02 40.9167 164.676 41.5834 165.835 42.6667C166.829 43.6667 167.326 45.0001 167.326 46.5834V46.6667C167.326 49.4167 165.836 51.1667 163.599 52.0001L167.823 58.0834H163.351L159.624 52.5834H159.541H156.56V58.0834H152.667V40.9167H152.833ZM160.535 49.1667C162.44 49.1667 163.434 48.1667 163.434 46.7501V46.6667C163.434 45.0834 162.357 44.2501 160.452 44.2501H156.642V49.1667H160.535Z" fill="#51AAF3"/>
<path d="M170.557 50.6667V40.8333H174.366V50.5C174.366 53.3333 175.774 54.6667 178.093 54.6667C180.412 54.6667 181.82 53.25 181.82 50.5V40.75H185.63V50.4167C185.63 55.5833 182.648 58.1667 178.011 58.1667C173.373 58.1667 170.557 55.6667 170.557 50.6667Z" fill="#51AAF3"/>
<path d="M188.362 55.5834L190.681 52.9167C192.255 54.1667 193.911 55.0001 195.899 55.0001C197.473 55.0001 198.384 54.3334 198.384 53.4167V53.3334C198.384 52.3334 197.721 51.8334 194.905 51.1667C191.427 50.2501 189.108 49.3334 189.108 45.9167V45.8334C189.108 42.7501 191.592 40.6667 195.236 40.6667C197.721 40.6667 199.957 41.4167 201.696 42.8334L199.709 45.7501C198.218 44.6667 196.644 44.0834 195.154 44.0834C193.663 44.0834 192.917 44.7501 192.917 45.5834V45.5001C192.917 46.5834 193.663 47.0001 196.644 47.7501C200.206 48.6667 202.111 49.9167 202.111 52.8334V52.9167C202.111 56.3334 199.46 58.2501 195.733 58.2501C193.166 58.2501 190.433 57.3334 188.362 55.5834Z" fill="#51AAF3"/>
<path d="M205.424 40.9167H209.234V47.7501H216.273V40.9167H220.083V58.0834H216.273V51.2501H209.234V58.0834H205.424V40.9167Z" fill="#51AAF3"/>
<path d="M31.5826 50.5834C31.4998 50.5834 31.4998 50.5001 31.5826 50.5834L31.1685 50.3334L31.0857 50.2501L21.3128 43.2501C21.3128 43.2501 21.23 43.2501 21.23 43.1667L21.0644 43.0001L20.9815 42.9167H20.8159L18 40.9167L23.549 41.7501C23.7975 41.7501 23.8803 41.8334 24.1287 41.9167C24.2116 41.9167 24.3772 42.0001 24.3772 42.0001C24.6257 42.0834 24.7085 42.1667 24.8741 42.2501C25.0398 42.3334 25.1226 42.4167 25.2882 42.5001C25.3711 42.5834 25.5367 42.6667 25.5367 42.7501C25.6195 42.8334 25.7023 42.8334 25.7852 42.9167C25.9508 43.0001 26.0336 43.0834 26.0336 43.1667C26.2821 43.4167 26.4477 43.6667 26.6962 43.8334L31.0857 50.0834L31.1685 50.1667L31.5826 50.5834Z" fill="#2AB4FF"/>
<path d="M31.5828 60.2501L31.1687 59.5834L31.0859 59.5001L18.083 40.9167L20.8989 42.9167L21.3958 43.2501L31.1687 50.2501H31.2515L31.5828 50.5001C31.5828 50.5001 31.6656 50.5001 31.6656 50.5834L32.0797 50.2501L45.2482 40.9167L31.9969 59.5834L31.9141 59.6667L31.5828 60.2501Z" fill="#51AAF3"/>
<path d="M31.5824 47.4166L31.1683 46.7499L31.0855 46.6666L27.8555 42.1666L31.0855 42.8333H31.1683L31.5824 42.9166L31.9965 42.8333L35.1437 42.0833L31.9965 46.7499V46.8333L31.5824 47.4166Z" fill="#2AB4FF"/>
<path d="M45.3303 40.8333L41.1892 41.4166L39.6157 41.6666C38.3733 41.8333 37.2138 42.4999 36.4685 43.5833L32.079 49.9166L31.9961 49.9999L31.582 50.5833L32.079 50.0833L32.1618 49.9999L31.582 50.5833L42.7628 42.5833L45.3303 40.8333Z" fill="#2AB4FF"/>
<path d="M33.6536 27.6666C33.6536 31.3333 27.3592 33.2499 27.3592 37.1666C27.2763 38.0833 27.5248 38.9999 27.9389 39.8333C27.9389 39.8333 25.5371 37.4999 25.5371 34.9999C25.5371 32.4999 27.8561 28.7499 27.8561 28.7499C27.8561 30.4166 28.5187 30.9166 29.1812 30.9999H29.264C30.0094 30.9999 30.4235 30.4166 30.4235 29.5833C30.4235 28.7499 29.5125 27.9166 29.5125 26.2499C29.5125 24.6666 31.5002 22.0833 31.5002 22.0833C32.9082 23.7499 33.6536 25.6666 33.6536 27.6666Z" fill="#2E9FFC"/>
<path d="M31.914 41C29.9263 41 28.4355 39.4167 28.4355 37.5V37.4167C28.4355 33.1667 34.3158 32.75 34.3158 27C34.4815 27.25 36.0551 29.4167 36.0551 31.75C36.0551 34.6667 33.322 35.4167 33.322 36.8333C33.322 37.4167 33.5704 37.8333 34.3986 37.8333C34.4815 37.8333 34.5643 37.8333 34.6471 37.75C36.2207 37.0833 37.2974 35.5833 37.3802 33.8333C37.3802 33.9167 38.2084 41 31.914 41Z" fill="#4EA1E5"/>
<path d="M34.482 37.8333C34.5648 37.8333 34.6476 37.8333 34.7305 37.75C33.9023 38 30.8379 38.75 30.8379 36.5833C30.8379 33.9167 35.393 33.3333 35.393 31.0833C35.393 29.6667 35.0618 28.25 34.3164 27C34.482 27.25 36.0556 29.4167 36.0556 31.75C36.0556 34.6667 33.3225 35.4167 33.3225 36.8333C33.4053 37.4167 33.7366 37.8333 34.482 37.8333Z" fill="#51AAF3"/>
<path d="M31.9975 29.1666C31.9975 31.2499 29.3473 31.0833 29.1816 30.9999H29.4301C30.1755 30.9999 30.5896 30.4166 30.5896 29.5833C30.5896 28.7499 29.6786 27.9166 29.6786 26.2499C29.6786 24.6666 31.6663 22.0833 31.6663 22.0833C31.0037 23.1666 30.6724 24.3333 30.5896 25.4999C30.5896 27.4166 31.9975 27.5833 31.9975 29.1666Z" fill="#51AAF3"/>
<path d="M30.3406 65.5833L28.5186 64.3333H30.7547L31.5001 62.0833L32.1627 64.3333H34.3988L32.5768 65.5833L33.2393 67.7499L31.4173 66.4166L29.5124 67.7499L30.3406 65.5833Z" fill="#51AAF3"/>
<path d="M39.865 65.5833L38.043 64.3333H40.2791L41.0245 62.0833L41.6871 64.3333H43.9232L42.1012 65.5833L42.7638 67.7499L40.9417 66.4166L39.0368 67.7499L39.865 65.5833Z" fill="#51AAF3"/>
<path d="M20.8992 65.5833L19.0771 64.3333H21.3133L22.0587 62.0833L22.7213 64.3333H24.9574L23.1354 65.5833L23.7979 67.7499L21.9759 66.4166L20.1538 67.7499L20.8992 65.5833Z" fill="#51AAF3"/>
</g>
<defs>
<clipPath id="clip0_0_1">
<rect width="202" height="46" fill="white" transform="translate(18 22)"/>
</clipPath>
</defs>
</svg>

  )
}
