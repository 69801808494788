import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";

const svgVariants = {
  hidden: { rotate: 0 },
  visible: {
    rotate: 0,
    transition: { duration: 1 },
  },
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

export default function Clutch49ReviewSvg() {
  const [isInView, setIsInView] = useState(false);
  const controls = useAnimation();
  const svgRef = useRef(null);

  useEffect(() => {
    let isMounted = true; // Tracks if the component is mounted

    const observer = new IntersectionObserver(
      (entries) => {
        if (!isMounted) return;

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            controls.start("visible");
          } else {
            setIsInView(false);
            controls?.start("hidden");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (svgRef.current) {
      observer.observe(svgRef.current);
    }

    return () => {
      isMounted = false; // Prevent updates if the component is unmounted
      if (svgRef.current) {
        observer.unobserve(svgRef.current);
      }
    };
  }, [controls]);
  return (
    <motion.svg
      ref={svgRef}
      variants={svgVariants}
      initial="hidden"
      animate={controls}
      width="182"
      height="103"
      viewBox="0 0 182 103"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className="!w-[70px] md:!w-[105px] lg:!w-[160px]"
    >
      <motion.path
        variants={pathVariants}
        initial="hidden"
        animate={controls}
        d="M15.0293 66.6436C22.7119 97.4574 154.343 115.726 176.658 59.7083C198.973 3.69076 36.0409 -11.8588 13.6002 17.6816C-8.84056 47.222 2.77397 99.1057 63.613 99.6575"
        stroke="#FF8E5E"
        strokeWidth="4"
        strokeLinecap="round"
      />
    </motion.svg>
  );
}
