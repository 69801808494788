import React from "react";
import { motion } from "framer-motion";

const svgVariants = {
  hidden: { rotate: -10 },
  visible: {
    rotate: 0,
    transition: { duration: 1 },
  },
};
const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};
export default function CurveClutchReviewSvg() {
  return (
    <motion.svg
      variants={svgVariants}
      initial="hidden"
      whileInView="visible"
      width="88"
      height="59"
      viewBox="0 0 88 59"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className="w-[60px] md:w-[90px] lg:w-[90px]"
    >
      <motion.path
        variants={pathVariants}
        initial="hidden"
        whileInView="visible"
        d="M8.66659 25.0612C1.38724 41.5351 -7.1889 69.5387 16.7443 49.7706C46.6601 25.0603 48.4553 -2.32877 36.7882 2.43446C25.1211 7.19768 -0.00824845 67.6341 27.2153 55.428C54.4388 43.2219 87.945 2.13736 67.9011 6.00736C47.8572 9.87736 25.4192 61.0845 44.865 55.428C64.3108 49.7716 86.7479 25.6564 86.1498 12.259"
        stroke="#FF8E5E"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </motion.svg>
  );
}
