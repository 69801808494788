import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";

const svgVariants = {
  hidden: { opacity: 0 }, // Initial position
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
      ease: [0, 0.71, 0.2, 1.01], // Custom easing
    },
  },
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

export default function ClutchNameSvg() {
  const [isInView, setIsInView] = useState(false);
  const controls = useAnimation();
  const svgRef = useRef(null);

  useEffect(() => {
    let isMounted = true; // Tracks if the component is mounted

    const observer = new IntersectionObserver(
      (entries) => {
        if (!isMounted) return;

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            controls.start("visible");
          } else {
            setIsInView(false);
            controls?.start("hidden");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (svgRef.current) {
      observer.observe(svgRef.current);
    }

    return () => {
      isMounted = false; // Prevent updates if the component is unmounted
      if (svgRef.current) {
        observer.unobserve(svgRef.current);
      }
    };
  }, [controls]);

  return (
    <motion.svg
      ref={svgRef}
      variants={svgVariants}
      initial="hidden"
      animate={controls}
      xmlns="https://www.w3.org/2000/svg"
      width="600"
      height="170"
      viewBox="0 0 600 170"
      fill="none"
      className="w-[100px] md:w-[164px] lg:w-[220px] h-auto"
    >
      <g clipPath="url(#clip0_883_4323)">
        <motion.path
          variants={pathVariants}
          initial="hidden"
          animate={controls}
          d="M156 -0.0999756H183.6V170.3H156V-0.0999756Z"
          fill="#1D1E22"
          className="dark-img-fill"
        />
        <motion.path
          variants={pathVariants}
          initial="hidden"
          animate={controls}
          d="M279.6 116.3C279.6 142.7 258 145.1 250.8 145.1C234 145.1 231.6 129.5 231.6 121.1V55.1H204V119.9C204 135.5 208.8 149.9 218.4 158.3C226.8 166.7 237.6 170.3 250.8 170.3C260.4 170.3 272.4 167.9 279.6 160.7V170.3H307.2V55.1H279.6V116.3Z"
          fill="#1D1E22"
          className="dark-img-fill"
        />
        <motion.path
          variants={pathVariants}
          initial="hidden"
          animate={controls}
          d="M361.2 13.1H333.6V55.1H313.2V81.5H333.6V170.3H361.2V81.5H381.6V55.1H361.2V13.1Z"
          fill="#1D1E22"
          className="dark-img-fill"
        />
        <motion.path
          variants={pathVariants}
          initial="hidden"
          animate={controls}
          d="M468 135.5C462 140.3 453.6 143.9 445.2 143.9C426 143.9 412.8 129.5 412.8 110.3C412.8 91.1 426 77.9001 445.2 77.9001C453.6 77.9001 462 80.3 468 86.3L471.6 89.9001L490.8 71.9001L486 68.3C475.2 58.7 460.8 52.7001 445.2 52.7001C411.6 52.7001 386.4 77.9001 386.4 111.5C386.4 145.1 411.6 170.3 445.2 170.3C460.8 170.3 475.2 164.3 486 154.7L490.8 151.1L471.6 131.9L468 135.5Z"
          fill="#1D1E22"
          className="dark-img-fill"
        />
        <motion.path
          variants={pathVariants}
          initial="hidden"
          animate={controls}
          d="M588 64.7C579.6 56.3 571.2 52.7 558 52.7C548.4 52.7 538.8 55.1 531.6 62.3V-0.0999756H504V170.3H531.6V106.7C531.6 80.3 549.6 77.9 556.8 77.9C573.6 77.9 572.4 93.5 572.4 101.9V169.1H600V103.1C600 87.5 596.4 73.1 588 64.7Z"
          fill="#1D1E22"
          className="dark-img-fill"
        />
        <motion.path
          variants={pathVariants}
          initial="hidden"
          animate={controls}
          d="M444 130.7C454.604 130.7 463.2 122.104 463.2 111.5C463.2 100.896 454.604 92.3 444 92.3C433.396 92.3 424.8 100.896 424.8 111.5C424.8 122.104 433.396 130.7 444 130.7Z"
          fill="#EF4335"
        />
        <motion.path
          variants={pathVariants}
          initial="hidden"
          animate={controls}
          d="M117.6 128.3C108 137.9 93.6 143.9 78 143.9C48 143.9 26.4 119.9 26.4 87.5C26.4 55.1 48 31.1 79.2 31.1C93.6 31.1 108 37.1 118.8 47.9L122.4 51.5L140.4 33.5L136.8 29.9C121.2 14.3 100.8 5.89999 79.2 5.89999C33.6 4.69999 0 40.7 0 87.5C0 134.3 33.6 170.3 78 170.3C99.6 170.3 121.2 161.9 135.6 146.3L139.2 142.7L121.2 124.7L117.6 128.3Z"
          fill="#1D1E22"
          className="dark-img-fill"
        />
      </g>
      <defs>
        <clipPath id="clip0_883_4323">
          <rect
            width="600"
            height="170"
            fill="white"
            className="dark-img-fill"
          />
        </clipPath>
      </defs>
    </motion.svg>
  );
}
